export const EXHIBITOR_COMPANY_EVENTS_FRAGMENT = `
  fragment exhibitorCompanyEventsFragment on Exhibitor {
    uid
    name
    _isVisited(myUid: "%authUser%")
    _subEditions {
      id
      uid
      code
      name
      schemaCode
      startTime
      endTime
      logoFileResource {
        schemaCode
        path
     }
     bannerFileResource {
        schemaCode
        path
     }
     venue {
        name
     }
    }
  }
`;
